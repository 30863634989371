import React, { useEffect, useState } from "react";
import McsCustomTabs from "src/components/molecules/mcs-custom-tabs";
import LocalCharges from "../../../prices/elements/local-charges";
import CustomerDatatable from "../customers/datatable";
import UsersDatatable from "../users/datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";
import CustomersTab from "../customers";
import UsersTab from "../users";

const tabs = [
  {
    label: "Customers",
    children: <CustomersTab />,
  },
  {
    label: "Users",
    children: <UsersTab />,
  },
];

const UserTabsComponent = ({ tabChanged }: any) => {
  const [activeKey, setActiveKey] = useState(
    sessionStorage.getItem("defaultActiveKey_UserManagement") ?? "0",
  );

  const handleChange = (index: any) => {
    setActiveKey(index.toString());
    tabChanged(tabs[index].label);
    sessionStorage.setItem("defaultActiveKey_UserManagement", index);
  };

  useEffect(() => {
    const storedKey = sessionStorage.getItem("defaultActiveKey_UserManagement");
    if (storedKey !== null) {
      setActiveKey(storedKey);
    }
  }, []);

  return (
    <McsCustomTabs
      tabs={tabs}
      defaultActiveKey={activeKey}
      onChange={handleChange}
    />
  );
};

export default UserTabsComponent;
