import { useLayoutEffect, useState } from "react";
import { formatSortingPayload } from "src/helpers";

interface PaginationState {
  currentPage: number;
  itemsPerPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  query: string;
  total: number;
  totalPages: number;
  loading: boolean;
  filters?: any;
  sorting?: any;
}

interface UsePaginationProps {
  key: string;
  mutation: any; // Replace YourMutationType with the actual type of your mutation
  filtersData?: any;
}

const usePagination = ({ key, mutation, filtersData }: UsePaginationProps) => {
  const defaultPagination: PaginationState = {
    currentPage: 1,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false,
    query: "",
    total: 0,
    totalPages: 0,
    loading: true,
    filters: null,
    sorting: {},
  };

  const [pagination, setPagination] = useState<PaginationState>(() => {
    const storedData = sessionStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultPagination;
  });

  useLayoutEffect(() => {
    for (let i = sessionStorage.length - 1; i >= 0; i--) {
      const key: any = sessionStorage.key(i);
      if (key.includes("datatable")) {
        sessionStorage.removeItem(key); // Remove the item if the key includes "datatable"
      }
    }
    sessionStorage.setItem(key, JSON.stringify(pagination));
  }, [
    key,
    pagination?.currentPage,
    pagination?.itemsPerPage,
    pagination?.query,
  ]);

  const [data, setData] = useState<any[]>([]);

  const handlePageChange = (page: number, total: any, totalPages: any) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      itemsPerPage: pageSize,
    }));
  };

  const handleSearch = (value: string) => {
    setPagination((prev) => ({ ...prev, currentPage: 1, query: value }));
  };

  const clearSearch = () => {
    setPagination((prev) => ({ ...prev, query: "" }));
  };

  const cleanFiltersData = () => {
    const cleanedData: any = {};
    for (const key in filtersData) {
      if (filtersData[key] !== null && filtersData[key] !== "") {
        cleanedData[key] = filtersData[key];
      }
    }
    return cleanedData;
  };

  const fetchData = ({ currentPage, query }: any) => {
    setPagination((prev) => ({ ...prev, currentPage, query, loading: true }));

    mutation?.({
      page: currentPage,
      perPage: pagination?.itemsPerPage,
      search: pagination?.query || "",
      sorting: formatSortingPayload(pagination?.sorting),
      ...(cleanFiltersData() ?? {}),
    })
      .unwrap()
      .then((res: any) => {
        setData(res?.data?.data);
        setPagination((prev) => ({
          ...prev,
          total:
            res?.data?.total ||
            res?.data?.pagination?.total ||
            res?.data?.data?.total ||
            res?.data?.data?.total ||
            0,
          totalPages:
            res?.data?.last_page ||
            res?.data?.pagination?.last_page ||
            res?.data?.data?.last_page ||
            res?.data?.data?.total ||
            0,
        }));
        if (res?.data?.data?.length == 0 && pagination?.currentPage > 1) {
          setPagination((prev) => ({
            ...prev,
            currentPage: prev?.currentPage - 1,
          }));
        }
      })
      .catch((err: any) => {
        console.log(err, "errro");
      })
      .finally(() => {
        setPagination((prev) => ({ ...prev, loading: false }));
      });
  };

  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData({
        currentPage: pagination?.currentPage,
        query: pagination?.query,
        sorting: pagination?.sorting,
      });
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [
    pagination?.currentPage,
    pagination?.itemsPerPage,
    pagination?.query,
    pagination?.filters,
    pagination?.sorting,
    filtersData,
  ]);

  return {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
    setPagination,
  };
};

export default usePagination;
