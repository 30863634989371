import React, { useEffect, useLayoutEffect, useState } from "react";
import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteCustomerUserMutation,
  useGetCustomerUserByIdMutation,
  useGetCustomerUsersListMutation,
  useGetUsersListMutation,
} from "src/services/api-service/user";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { setLoading } from "src/store/features/alerts";
import { ENDPOINTS } from "src/store/endpoints";
import useCheckDisabled from "src/hooks/useCheckDisabled";
import SelectInput from "src/components/atoms/select-input";

const Datatable = ({ formik, resetError, getId, reFetchData }: any) => {
  const [getListMutation] = useGetCustomerUsersListMutation<any>();
  const [getUserListMutation] = useGetUsersListMutation<any>();
  const [deleteItemMutation] = useDeleteCustomerUserMutation<any>();
  const [getItemMutation] = useGetCustomerUserByIdMutation<any>();

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [url] = useSearchParams();
  const customerId: string = url?.get("id") ?? "";
  const customerName = url?.get("name") ?? "";
  const [customers, setCustomers] = useState<any>(null);

  const [storedData, setStoredData] = useState<any>({
    name: "",
    role: null,
    customerid: "",
    phone: "",
    email: "",
  });

  const requiredKeys = ["name", "role", "customerid", "phone", "email"];
  const { isDisabled, handleInputChange } = useCheckDisabled();
  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleInputChange(formik.values, requiredKeys, storedData, updateId);
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [formik.values, isDisabled, storedData, updateId]);

  const handleOpenAddModal = () => {
    formik.resetForm();
    setUpdateId("");
    getId("");
    setAddModalOpen(true);
  };

  const [filtersData] = useState({
    id: customerId,
  });

  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadEndpoint = ENDPOINTS.ADMIN.DOWNLOAD_CUSTOMER_USER;
  const [isDownload, setIsDownload] = useState(false);
  const dispatch = useAppDispatch();

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    setTimeout(() => {
      formik.resetForm();
    }, 1000);
  };
  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "user-management-datatable",
    mutation: getListMutation,
    filtersData,
  });

  const user = useAppSelector(userSelector);

  const handleCellClick = (event: any) => {
    setUpdateId(event?.data?.customer_user_id);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleOpenEditModal = () => {
    console.log("Edit modal");
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };
    try {
      updateId &&
        deleteItemMutation(body)
          .unwrap()
          .then((data) => {
            showToast(TOASTR_TYPES.SUCCESS, data?.message);
          });
      fetchData({ currentPage: pagination?.currentPage, query: "" });
    } catch (err) {
      console.log(err);
    }
    setDeleteModalOpen(false); // Close the modal after deletion
  };

  useEffect(() => {
    if (!isDeleteModalOpen) {
      updateId && getDataById();
    }
  }, [updateId]);

  useEffect(() => {
    if (!isDeleteModalOpen) {
      !isAddModalOpen && setUpdateId("");
    }
  }, [isAddModalOpen]);

  const getDataById = async () => {
    if (updateId) {
      const data: any = await getItemMutation({ id: updateId })
        .unwrap()
        .catch(() => {
          // console.log(err)
        });
      const finalData: any = data?.data;
      const fieldsToUpdate: any[] = Object.keys(formik?.values) || [];
      setStoredData(finalData);

      fieldsToUpdate.forEach((field) => {
        if (finalData?.[field]) {
          formik.setFieldValue(field, finalData?.[field]);
        }
      });
      console.log("Updateinnn----");

      formik.setFieldValue("phone", finalData?.phone);
      formik.setFieldValue("customerid", customerId);
      setAddModalOpen(true);
      getId(updateId);
    }
  };

  const [columnDefs] = useState<any[]>(
    columns({ handleOpenDeleteModal }, { handleOpenEditModal }),
  );

  const handleDownload = () => {
    setIsDownload(true);
  };

  const getFilenameFromHeaders = (headers: any) => {
    const contentDisposition = headers.get("content-disposition");
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (filenameMatch && filenameMatch[1]) {
        return filenameMatch[1].replace(/['"]/g, "");
      }
    }
    return null;
  };

  const downloadDataById = async () => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl =
        baseUrl + downloadEndpoint + "/" + customerId + "/download-pdf";
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const filename = getFilenameFromHeaders(response.headers);
        const blob = new Blob([await response.blob()], {
          type: response.headers.get("content-type") ?? "",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename ?? customerName + "_USERS.pdf"; // Specify the desired filename here
        link.click();
        showToast(
          TOASTR_TYPES.SUCCESS,
          `You successfully downloaded the datasource`,
        );
      } else {
        console.error(
          "Error downloading file. Server returned:",
          response.status,
        );
      }
    } finally {
      setIsDownload(false);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async (params?: any) => {
    const data: any = await getUserListMutation({
      perPage: params?.perPage || 100,
      search: params?.search || "",
    })
      .unwrap()
      .catch((err: any) => {
        // console.log(err)
      });
    const finalData: any = data?.data?.data;
    if (finalData && finalData.length > 0) {
      setCustomers([
        ...finalData.map((d: any) => {
          return {
            ...d,
            customerid: d?.customerid.toString(),
          };
        }),
      ]);
    }
  };

  useEffect(() => {
    if (isDownload) {
      downloadDataById();
    }
  }, [isDownload]);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (reFetchData) {
      formik.resetForm();
      fetchData({ currentPage: pagination?.currentPage, query: "" });
      setAddModalOpen(false);
    }
  }, [reFetchData]);

  return (
    <>
      <CustomDatatable
        title={customerName || ""}
        description={`${pagination.total} Total User${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        clearSearch={clearSearch}
        rowDraggable={false}
        onRowClicked={() => {
          console.log("row clicked");
        }}
        onCellClicked={(event: any) => handleCellClick(event)}
        button={
          <div className="flex items-center">
            <div className="flex items-center gap-3">
              <CustomButton
                disabled={!data?.length}
                variant="secondary-icon-btn"
                onClick={handleDownload}
              >
                <div className="flex items-center gap-2">
                  <i className="shrink-0">
                    <Icon.McsIcExportPdf />
                  </i>
                  <span>Export PDF</span>
                </div>
              </CustomButton>
              <CustomButton
                variant="primary-icon-btn"
                onClick={handleOpenAddModal}
              >
                <div className="flex items-center gap-2">
                  <i className="shrink-0">
                    <Icon.McsIcIconPlus />
                  </i>
                  <span>Add User</span>
                </div>
              </CustomButton>
            </div>
            {/* Add Client modal */}
            <form onSubmit={formik.handleSubmit}>
              <CustomModal
                title={(updateId ? "Edit" : "Add") + " Client"}
                isOpen={isAddModalOpen}
                toggle={handleCloseAddModal}
                maskClosable={false}
                icon={<Icon.McsIcChevronBack />}
                width={876}
                innerClass="custom-common-modal"
                footer={
                  <div className="w-full justify-end gap-4 lg:flex">
                    <div className="order-1 lg:order-2">
                      <CustomButton
                        variant="primary"
                        htmlType="submit"
                        disabled={isDisabled}
                        className="w-full"
                      >
                        Save
                      </CustomButton>
                    </div>
                    <div className="order-2 mt-10 lg:order-1 lg:mt-0">
                      <CustomButton
                        variant="secondary"
                        onClick={handleCloseAddModal}
                        className="w-full"
                      >
                        Cancel
                      </CustomButton>
                    </div>
                  </div>
                }
              >
                <div className="grid gap-6 gap-x-4 lg:grid-cols-3">
                  <div>
                    <TextInput
                      name="name"
                      type="text"
                      label="Name"
                      placeholder="Aleem Shaikh"
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.name}
                      showError={Boolean(formik.touched.name)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.name}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextInput
                      name="phone"
                      type="text"
                      label="Phone Number"
                      placeholder="ex. +31 123-4567-89"
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.phone}
                      showError={Boolean(formik.touched.phone)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.phone}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextInput
                      name="email"
                      type="text"
                      label="Email Address"
                      placeholder="aleem.shaikh@aci.ae"
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.email}
                      showError={Boolean(formik.touched.email)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.email}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextInput
                      name="designation"
                      type="text"
                      label="Designation"
                      placeholder="User"
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.designation}
                      showError={Boolean(formik.touched.designation)}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      errorMessage={formik.errors.designation}
                      required={false}
                    />
                  </div>
                  <div>
                    <SelectInput
                      label="Customer"
                      name="customer"
                      searchable={true}
                      options={customers}
                      value={formik.values.customerid}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onSelect={(e: any) => {
                        if (e == "Add Customer") {
                          setAddModalOpen(true);
                        } else {
                          formik.setFieldValue("customerid", e);
                        }
                      }}
                      placeholder="Select"
                      containerClassName="col-span-12 sm:col-span-5"
                    />
                  </div>
                </div>
              </CustomModal>
            </form>
            {/* Add Client modal end */}
          </div>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this user?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
    </>
  );
};

export default Datatable;
