import React from "react";

const McsIcApprove = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / check">
        <path
          id="icon"
          d="M16.6666 5.83203L8.08917 14.4094C7.76374 14.7349 7.49992 14.9987 6.91066 14.4094L3.33325 10.832"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default McsIcApprove;
