import React from "react";
import { IUserStatus } from "./types";
import { getUserStatusLabel } from "src/helpers";
import "./style.css";
const UserStatusComponent = ({ userStatus }: IUserStatus) => {
  return (
    <div className="flex h-full items-center">
      <div
        className={`rounded-2xl px-8 py-2 text-12 font-medium leading-18 ${getUserStatusLabel(userStatus)?.className}`}
      >
        {getUserStatusLabel(userStatus)?.label}
      </div>
    </div>
  );
};

export default UserStatusComponent;
