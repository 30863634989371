import React from "react";
import type { MenuProps } from "antd";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { setLoading } from "src/store/features/alerts";
import { useAppDispatch } from "src/store/hook";
import { ENDPOINTS } from "src/store/endpoints";
import { useNavigate } from "react-router-dom";
import { setData, setQuotations } from "src/store/features/quotations";
import CustomDropdown from "src/components/atoms/custom-dropdown";
import { Icon } from "src/components/atoms/icons";
import useUser from "src/hooks/useUser";

export default function Menu({ params, handleOpenDeleteModal }: any) {
  const dispatch = useAppDispatch();
  const user = useUser();
  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadCustomEndpoint =
    ENDPOINTS.ADMIN.DOWNLOAD_CUSTOM_QUOTATION + params?.data?.id + "/pdf";
  const downloadInternalEndpoint =
    ENDPOINTS.ADMIN.DOWNLOAD_QUOTATION_HISTORY + params?.data?.id;
  const navigate = useNavigate();

  const downloadPdf = async () => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl =
        baseUrl +
        (params?.quotation_type === "custom"
          ? downloadCustomEndpoint
          : downloadInternalEndpoint);
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type") ?? "";
        const filename = `${params?.data?.source_port || params?.data?.source_country}_to_${params?.data?.destination_port || params?.data?.destination_country}_quotation_for_${params?.data?.customer?.name || "all_customers"}.pdf`;
        const message =
          response.headers.get("message") ??
          "The Pdf has been successfully downloaded";
        const blob = new Blob([await response.blob()], {
          type: contentType,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast(TOASTR_TYPES.SUCCESS, message);
      } else {
        const errorMessage = await response.json();
        showToast(TOASTR_TYPES.ERROR, `${errorMessage?.error}`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `${error.message}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  let items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate(
              "/quotations?tab=Generate Quotations&id=" +
                params?.data?.id +
                "&type=" +
                params?.data.quotation_type,
            )
          }
          className="!text-primary-10"
        >
          View updated quotations
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => {
            navigate("/quotations/results?id=" + params?.data?.id);
            dispatch(setQuotations([]));
            dispatch(setData({}));
          }}
          className="!text-primary-10"
        >
          View previous results
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button className="!text-primary-10" onClick={downloadPdf}>
          Download PDF
        </button>
      ),
      key: "2",
    },
    // {
    //   label: (
    //     <button
    //       onClick={handleOpenDeleteModal}
    //       className="!text-error-danger-600"
    //     >
    //       Delete Quotation
    //     </button>
    //   ),
    //   key: "3",
    // },
  ];

  if (params?.data?.quotation_type === "custom") {
    items = [
      {
        label: (
          <button className="!text-primary-10" onClick={downloadPdf}>
            Download PDF
          </button>
        ),
        key: "2",
      },
      // {
      //   label: (
      //     <button
      //       onClick={() => handleOpenDeleteModal()}
      //       className="!text-error-danger-600"
      //     >
      //       Delete Quotation
      //     </button>
      //   ),
      //   key: "3",
      // },
    ];
  }

  const props = { items };

  return (
    <CustomDropdown {...props}>
      <i className="p-8">
        <Icon.McsIcUserDotsThreeVertical />
      </i>
    </CustomDropdown>
  );
}
