import React, { useEffect, useRef, useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import { IBreadcrumbItem, TITLES } from "src/types";
import UserTabs from "./elements/user-tabs";
import useQueryParams from "src/hooks/useQueryParams";
import ContentHeader from "src/components/layouts/content-header";
import CustomLink from "src/components/atoms/custom-link";
import UserDetails from "./elements/users/user-details";

const UserManagement = () => {
  const { getQueryParam } = useQueryParams();
  const userId = getQueryParam("userId");

  const userIdRef = useRef<string | null>(null);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.USER_MANAGEMENT;

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;

    return () => {
      sessionStorage.removeItem("defaultActiveKey_UserManagement");
    };
  }, []);

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "User Management",
    },
    {
      title: "Customers",
      hidden: false,
    },
  ];

  const [data, setData] = useState(breadcrumbsData);

  const tabChanged = (title: any) => {
    setData([
      {
        title: "User Management",
        isDisabled: true,
      },
      {
        title: title,
        hidden: false,
      },
    ]);
  };
  useEffect(() => {
    // If userId exists, append "User Details"
    if (userId) {
      setData((prevData) => [
        ...prevData,
        {
          title: "User Details",
          hidden: false,
        },
      ]);
    } else {
      // Remove "User Details" only if it was previously added (userId existed before)
      if (userIdRef.current) {
        setData((prevData) =>
          prevData.filter((item) => item.title !== "User Details"),
        );
      }
    }

    // Update the ref to track the previous value of userId
    userIdRef.current = userId;
  }, [userId]);

  return (
    <PanelLayout breadcrumbsData={data}>
      <>
        {userId && (
          <ContentHeader>
            <div className="">
              <CustomLink>Back</CustomLink>
            </div>
          </ContentHeader>
        )}
        {!userId ? <UserTabs tabChanged={tabChanged} /> : <UserDetails />}
      </>
    </PanelLayout>
  );
};

export default UserManagement;
