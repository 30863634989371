import React, { useEffect, useState } from "react";
import { CONSTANTS } from "src/constants";
import moment from "moment";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { toast } from "react-toastify";
import { Icon } from "src/components/atoms/icons";

export const hasEmptyValues = (obj: { [key: string]: string }): boolean => {
  if (Object.values(obj).includes("")) {
    return true;
  }
  return false;
};

export const hasUppercaseAndLowercase = (input: string) => {
  const hasUppercase = /[A-Z]/.test(input);
  const hasLowercase = /[a-z]/.test(input);
  return hasUppercase && hasLowercase;
};

export const hasNumbers = (input: string) => {
  const hasNumber = /[0-9]/.test(input);
  return hasNumber;
};

export const persistToken = (token: string) => {
  localStorage.setItem(CONSTANTS.TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(CONSTANTS.TOKEN) ?? "";
};

export const clearToken = () => {
  localStorage.removeItem(CONSTANTS.TOKEN);
  sessionStorage.removeItem(CONSTANTS.TOKEN);
};

export const showToast = (
  type: keyof typeof TOASTR_TYPES,
  message: string,
  title?: string,
) => {
  toast(
    <div
      className="ittailwind.config.js ems-start
flex"
    >
      <div className="mr-3 flex-shrink-0"></div>
      <div>
        <p className="mb-2 text-16 font-semibold leading-24 tracking-0.016">
          {title ?? (type === TOASTR_TYPES.SUCCESS ? "Success!" : "Failed!")}
        </p>
        <p className="text-14 font-normal leading-20 tracking-0.014">
          {message}
        </p>
      </div>
    </div>,
    {
      style: {
        backgroundColor: type === TOASTR_TYPES.SUCCESS ? "#039855" : "#D92037",
        border: "none",
        borderRadius: "4px",
        color: "white",
        padding: "14px 16px",
      },
    },
  );
};

export const checkBooleanOptions = (value: any) => {
  if (value === false || value === true || value === 0 || value === 1)
    return value;
  else {
    if (value === "0" || value === "1") return JSON.parse(value || "");
    else return value;
  }
};

export const formatDateResult = (date: any) => {
  // Get day, month, and year components from the Date object
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Concatenate components in the desired format
  return `${year}-${month}-${day}`;
};

export const formatDate = (date: string) => {
  return moment(date).format("D MMM YYYY");
};
export const formatWatchDate = (date: string) => {
  const formattedDate = moment(date).format("MMMM Do, YYYY [at] h:mm:ss A");
  return formattedDate;
};

export const getCurrentTimestamp = () => {
  return Date.now() / 1000;
};

export const convertTimestampToSeconds = (timestamp: number | string) => {
  return Math.floor(Number(timestamp) / 1000);
};
export const getOtpRequestTimestamp = () => {
  return localStorage.getItem("otpRequestTimestamp");
};

export const truncateString = (
  str: string | undefined,
  lengthToTruncate: number,
) => {
  if (str) {
    if (str?.length > lengthToTruncate) {
      return `${str.substring(0, lengthToTruncate)}...`;
    }
    return str;
  } else {
    return "";
  }
};

export const makeImageUrl = (img: string | undefined) => {
  return img ? (process.env.REACT_APP_IMG_URL ?? "") + img : "";
};

export const extractDomailFromUrl = () => {
  const url = window.location.origin;

  // Remove the protocol and any trailing slashes (if any)
  const trimmedURL = url.replace(/^(https?:\/\/)?/, "").replace(/\/$/, "");

  // Split the remaining URL by slashes to extract the domain
  const parts = trimmedURL.split("/");
  const domain = parts[0];

  return domain;
};

export function convertToMinutesAndSeconds(seconds: number): string {
  if (isNaN(seconds)) {
    return `"00", "00"`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatMillisecondsToMinutesSeconds(
  milliseconds: number,
): string {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

  return `${formattedMinutes}:${formattedSeconds}`;
}

export const generateRandomPassword = (passwordLength = 10) => {
  const numbers = "0123456789";
  const specialChars = "@";
  const smallAlphabets = "abcdefghijklmnopqrstuvwxyz";
  const upperAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const getRandomChar = (charset: any) => {
    const randomIndex = Math.floor(Math.random() * charset.length);
    return charset[randomIndex];
  };

  // Ensure at least one character from each category
  const password = [
    getRandomChar(numbers),
    getRandomChar(specialChars),
    getRandomChar(smallAlphabets),
    getRandomChar(upperAlphabets),
  ];

  // Fill the rest of the password with random characters, ensuring only one special character
  while (password.length < passwordLength) {
    const randomCategory = Math.floor(Math.random() * 3); // 0: numbers, 1: specialChars, 2: alphabets
    switch (randomCategory) {
      case 0:
        password.push(getRandomChar(numbers));
        break;
      case 1:
        if (!password.some((char) => specialChars.includes(char))) {
          password.push(getRandomChar(specialChars));
        }
        break;
      case 2:
        password.push(getRandomChar(smallAlphabets));
        break;
      case 3:
        password.push(getRandomChar(upperAlphabets));
        break;
    }
  }

  // Shuffle the password to randomize the order
  for (let i = password.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [password[i], password[j]] = [password[j], password[i]];
  }

  return password.join("");
};

export const checkObjectKeys = (obj: any, objToValidate: any) => {
  const objKeys = Object.keys(obj);
  const objToValidateKeys = Object.keys(objToValidate);
  console.log(objKeys, "obj keys");
  console.log(objToValidateKeys, "objToValidateKeys");
  return objToValidateKeys.some((valKey: any) => objKeys.includes(valKey));
};

export const capitalizeFirstLetter = (
  inputString: string | undefined,
): string | undefined => {
  if (inputString) {
    return inputString?.charAt(0)?.toUpperCase() + inputString?.slice(1);
  }
  return inputString;
};

export const handleDisplayNameLength = (name: string): string => {
  if (name) {
    if (name.length > 25) {
      return name.slice(0, 22) + "...";
    }
  }

  return name;
};

export const calculateColumnWidth = (fraction: number, minLimit: number) => {
  const [screenSize, setScreenSize] = useState({
    width:
      window.innerWidth > 768
        ? window.innerWidth - 298
        : window.innerWidth - 48,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width:
          window.innerWidth > 768
            ? window.innerWidth - 298
            : window.innerWidth - 48,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let minWidth;
  if (minLimit < screenSize.width * fraction) {
    minWidth = screenSize.width * fraction;
  } else {
    minWidth = minLimit;
  }
  return minWidth;
};

export const extractNumbers = (str: string) => {
  // Extract numbers
  // str = str
  //   .replace(/\s/g, "")
  //   .replace(/-/g, "")
  //   .replace(/[^\d.]/g, "") // Allow digits and one decimal point
  //   .replace(/^0+(?!\.)/, "") // Remove leading zeros unless followed by a decimal point
  //   .replace(/(\..*)\./g, "$1"); // Replace additional decimal points with empty string

  return str;
};

/**
 * A map containing status strings as keys and corresponding CSS class names as values.
 * Used for determining the class name based on the status.
 * @example const className = statusColor.get(params.data.status)
 */
export const statusColor = new Map<string, string>([
  ["booked", "bg-success-50 text-success-700"],
  ["requested", "bg-yellow-100 text-yellow-700"],
  ["pending", "bg-purple-50 text-purple-700"],
  ["failed", "bg-error-danger-50 text-error-danger-700"],
  ["complete", "bg-sky-50 text-sky-700"],
]);

export const findIconByValue = (mode: string) => {
  const icons = [
    { name: "ocean", icon: <Icon.McsIcShip /> },
    { name: "air", icon: <Icon.McsIcAir /> },
    { name: "road", icon: <Icon.McsIcTruck /> },
    { name: "rail", icon: <Icon.McsIcRail /> },
  ];

  return icons.find((icon) => icon.name === mode);
};
