import React from "react";
import { Icon } from "../icons";
import { ILogoutButton } from "./types";

const LogoutButtonComponent = ({ handleLogout }: ILogoutButton) => {
  return (
    <button
      className="flex w-full items-center gap-2 py-11 pl-16 pr-16 transition duration-[0.4s] hover:hover:bg-gray-300"
      onClick={handleLogout}
    >
      <Icon.McsIcLogoutAdmin />
      <span className="whitespace-nowrap text-14 font-medium leading-20 text-gray-700">
        Log out
      </span>
    </button>
  );
};

export default LogoutButtonComponent;
