import React from "react";
import AuthLayout from "src/components/layouts/auth-layout";

import FormHeader from "src/pages/auth/elements/form-header";
import { MAILING_ADDRESS } from "src/types";

const PendingApproval = () => {
  return (
    <AuthLayout>
      <div className="">
        <FormHeader
          title="Your signup is under review!"
          description={
            <div className="read flex flex-col gap-y-8">
              <p>
                Thank you for signing up with Marinair Cargo Services. We are
                currently reviewing your application to ensure everything is in
                order. This process helps us maintain the highest standards of
                service and security for all our users.
              </p>
              <p>
                It will take approximately 24-48 hours. Once your account is
                approved, you will receive a confirmation email with further
                instructions on how to access your account.
              </p>
              <p>
                If you have any questions or need assistance, please don&apos;t
                hesitate to{" "}
                <a
                  href={`mailto:${MAILING_ADDRESS}`}
                  className="text-16 font-bold leading-24 text-blue-1"
                >
                  Contact our support team
                </a>
              </p>
            </div>
          }
        />
      </div>
    </AuthLayout>
  );
};

export default PendingApproval;
