import React from "react";

const McsIcReject = () => {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Close">
        <path
          id="Vector"
          d="M11.3215 10.3549L15.6263 14.6596L15.1584 15.1275L10.8536 10.8227L10.5001 10.4692L10.1465 10.8227L5.84175 15.1275L5.37385 14.6596L9.67863 10.3549L10.0322 10.0013L9.67863 9.64775L5.37385 5.34297L5.84175 4.87508L10.1465 9.17986L10.5001 9.53341L10.8536 9.17986L15.1584 4.87508L15.6263 5.34297L11.3215 9.64775L10.968 10.0013L11.3215 10.3549Z"
          fill="white"
          stroke="white"
        />
      </g>
    </svg>
  );
};

export default McsIcReject;
