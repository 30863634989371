import React from "react";
import Menu from "./menu";
import { formatDate, statusColor } from "src/utils";
import { formatQuotationReference } from "src/helpers";

export const getColumns = (handleOpenDeleteModal: () => void) => [
  {
    headerName: "Reference No.",
    field: "reference_no",
    width: 70,
    headerClass: "", // Add this line
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 200,
    cellRenderer: ({ value, data }: any) => {
      return (
        <div className="text-wrap overflow-auto">
          <span>{formatQuotationReference(data)}</span>
        </div>
      );
    },
  },
  {
    headerName: "Creation Date",
    field: "created_at",
    flex: 1,
    width: 70,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 120,
    cellRenderer: ({ value }: any) => <span>{formatDate(value)}</span>,
  },
  {
    headerName: "Origin",
    field: "source_country",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 160,
  },
  {
    headerName: "Destination",
    field: "destination_country",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 160,
  },
  {
    headerName: "Shipment Mode",
    field: "mode_of_transport",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 180,
    valueGetter: (params: any) => params.data.mode_of_transport.toUpperCase(),
  },
  {
    headerName: "Service Type",
    field: "service_type",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 120,
    valueGetter: (params: any) => params.data.service_type.toUpperCase(),
  },
  {
    headerName: "Status",
    field: "status",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    cellStyle: {
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      alignItems: "center",
    },
    minWidth: 100,
    cellRenderer: (params: any) => {
      return (
        <div
          className={`bg-error-50 text-error-700 w-fit rounded-2xl px-8 py-2 text-12 font-medium capitalize leading-18 ${statusColor.get(params.data.status)}`}
        >
          {params.data.status}
        </div>
      );
    },
  },
  {
    headerName: "Actions",
    field: "",
    lockPosition: "right",
    suppressNavigable: true,
    resizable: false,
    width: 100,
    cellRenderer: (params: any) => (
      <Menu params={params} handleOpenDeleteModal={handleOpenDeleteModal} />
    ),
    cellStyle: { paddingLeft: 12, paddingRight: 12 },
    sortable: false,
  },
];
