import React from "react";
import ContentWrapper from "src/components/layouts/content-wrapper";
import Datatable from "./datatable";

const CustomersTab = () => {
  return (
    <ContentWrapper>
      <Datatable />
    </ContentWrapper>
  );
};

export default CustomersTab;
