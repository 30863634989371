import React from "react";
import { Icon } from "src/components/atoms/icons";
import useFormattedDate from "src/hooks/useFormattedDate";
import UserStatus from "../../user-status";

export const columns = (
  { handleOpenDeleteModal }: any,
  { handleOpenEditModal }: any,
) => {
  return [
    {
      headerName: "Client Name",
      field: "name",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 100,
      cellRenderer: ({ value, data }: any) => {
        return <span>{value}</span>;
      },
    },
    {
      headerName: "Customer",
      field: "customer",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 150,
      cellRenderer: ({ value }: any) => {
        return <span>{value?.name || "-"}</span>;
      },
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 70,
      cellRenderer: ({ value }: any) => {
        return <span>{value}</span>;
      },
    },
    {
      headerName: "Status",
      field: "is_accepted",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 90,
      cellRenderer: ({ value }: any) => {
        return <UserStatus userStatus={value} />;
      },
    },
    {
      headerName: "Latest Activity",
      field: "updated_at",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 100,
      resizable: true,
      cellRenderer: ({ value }: any) =>
        value ? <div className="">{useFormattedDate(value)}</div> : "-",
    },
    {
      headerName: "Actions",
      field: "actions",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 100,
      cellRenderer: ({ value, data }: any) => {
        return (
          <div className="flex h-full items-center justify-end">
            <button
              className="cursor-pointer p-8"
              onClick={() => handleOpenEditModal(data?.id)}
            >
              <Icon.McsIcEdit />
            </button>

            <button
              className="cursor-pointer p-8"
              onClick={() => handleOpenDeleteModal(data?.id)}
            >
              <Icon.McsIcDelete />
            </button>
          </div>
        );
      },
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
