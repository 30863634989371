import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import AddCustomerComponent from "./approve-user.component";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import useLoadingError from "src/hooks/useLoadingError";
import { useApproveUserMutation } from "src/services/api-service/user";
import { IResponse } from "src/services/types";
import { userSchema } from "src/validations/user-schema";
import useTitleCase from "src/hooks/useTitleCase";
import useQueryParams from "src/hooks/useQueryParams";

const ApproveUserComponent = ({
  handleCloseApproveModal,
  updateId,
  isApproveModalOpen,
  refetchData,
}: any) => {
  const { reset, startLoading, stopLoading } = useLoadingError();

  const { getQueryParam } = useQueryParams();
  const userId = getQueryParam("userId");
  const [approveUser] = useApproveUserMutation<any>();
  const { handleInputChange } = useTitleCase();
  const [closeModalState] = useState(false);

  const [isNewCustomer, setisNewCustomer] = useState(1);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const formik = useFormik({
    initialValues: {
      role: "",
      name: "",
      email: "",
      street: "",
      postalCode: "",
      city: "",
      country: null,
      contactPersonName: "",
      contactPersonPhoneNumber: "",
      contactPersonEmail: "",
      customer_id: "",
      designation: "",
    },
    validationSchema: userSchema(Boolean(isNewCustomer)),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: ({ customer_id, ...customer }) => {
      startLoading();

      approveUser({
        customer,
        id: userId,
        customer_id,
        is_new_customer: Boolean(isNewCustomer),
      })
        .unwrap()
        .then((res: any) => {
          showToast(TOASTR_TYPES.SUCCESS, res?.message);
          refetchData();
        })
        .catch((err: IResponse) => {
          // showToast(TOASTR_TYPES.ERROR, err?.data?.message);
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            stopLoading();
          }, 1000);
        });
    },
  });

  console.log(formik, "formik comes here>>>");

  useEffect(() => {
    handleInputChange(formik.values.name, "name");
  }, [formik.values.name]);

  return (
    <AddCustomerComponent
      formik={formik}
      resetError={reset}
      handleCloseApproveModal={() => {
        formik.resetForm();
        handleCloseApproveModal();
      }}
      updateId={updateId}
      closeModal={closeModalState}
      isApproveModalOpen={isApproveModalOpen}
      isNewCustomer={isNewCustomer}
      setisNewCustomer={setisNewCustomer}
    />
  );
};

export default ApproveUserComponent;
