import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import SelectInput from "src/components/atoms/select-input";
import {
  useAddCustomerUserMutation,
  useGetCustomerUserByIdMutation,
  useGetUsersListMutation,
  useUpdateCustomerUserMutation,
} from "src/services/api-service/user";
import useCheckDisabled from "src/hooks/useCheckDisabled";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { customerUserSchema } from "src/validations/customer-user-schema";
import { useFormik } from "formik";
import useLoadingError from "src/hooks/useLoadingError";

const AddUserComponent = ({
  id,
  setId,
  isAddModalOpen,
  setAddModalOpen,
  refetchData,
}: any) => {
  const [addItem] = useAddCustomerUserMutation<any>();
  const [updateItem] = useUpdateCustomerUserMutation<any>();
  const [getItemMutation] = useGetCustomerUserByIdMutation<any>();
  const [getUserListMutation] = useGetUsersListMutation();
  const { loading, startLoading, stopLoading } = useLoadingError();

  const formik = useFormik({
    initialValues: {
      name: "",
      role: "user",
      designation: "",
      customerid: "",
      phone: "",
      email: "",
    },
    validationSchema: customerUserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      startLoading();
      let APIHIT;
      !id &&
        (APIHIT = addItem({
          ...values,
          phoneNumber: values?.phone,
        }));
      id &&
        (APIHIT = updateItem({
          ...values,
          id,
          customerid: values?.customerid,
          phoneNumber: values?.phone,
        }));
      APIHIT &&
        APIHIT.unwrap()
          .then((res) => {
            showToast(TOASTR_TYPES.SUCCESS, res?.message);
            setAddModalOpen(false);
            setId("");
            refetchData();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            stopLoading();
          });
    },
  });

  // Reset form and set id to "" when modal is closed
  useEffect(() => {
    if (!isAddModalOpen) {
      setId(false);
      formik.resetForm();
    }
  }, [isAddModalOpen]);

  const [customers, setCustomers] = useState<any>(null);

  const [storedData, setStoredData] = useState<any>({
    name: "",
    role: null,
    customerid: "",
    phone: "",
    email: "",
  });

  console.log(storedData, "storedData");

  const { isDisabled, handleInputChange } = useCheckDisabled();

  const requiredKeys = ["name", "customerid", "phone", "email"];

  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleInputChange(formik.values, requiredKeys, storedData, id);
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [formik.values, isDisabled, storedData, id]);

  useEffect(() => {
    isAddModalOpen && id && getDataById();
  }, [id, isAddModalOpen]);

  const getDataById = async () => {
    if (id) {
      const data: any = await getItemMutation({ id })
        .unwrap()
        .catch((err) => {
          console.log(err);
        });
      const finalData: any = data?.data;
      const fieldsToUpdate: any[] = Object.keys(formik?.values) || [];
      setStoredData({ ...finalData });

      fieldsToUpdate.forEach((field) => {
        if (finalData?.[field]) {
          formik.setFieldValue(field, finalData?.[field]);
        }
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async (params?: any) => {
    const data: any = await getUserListMutation({
      perPage: params?.perPage || 100,
      search: params?.search || "",
    }).unwrap();

    const finalData: any = data?.data?.data;
    if (finalData && finalData.length > 0) {
      setCustomers([
        ...finalData.map((d: any) => {
          return {
            ...d,
            customerid: d?.customerid.toString(),
          };
        }),
      ]);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <CustomModal
        title={(id ? "Edit" : "Add") + " Client"}
        isOpen={isAddModalOpen}
        toggle={() => setAddModalOpen(!isAddModalOpen)}
        maskClosable={false}
        icon={<Icon.McsIcChevronBack />}
        width={876}
        innerClass="custom-common-modal"
        footer={
          <div className="w-full justify-end gap-4 lg:flex">
            <div className="order-1 lg:order-2">
              <CustomButton
                variant="primary"
                htmlType="submit"
                disabled={isDisabled}
                className="w-full"
              >
                Save
              </CustomButton>
            </div>
            <div className="order-2 mt-10 lg:order-1 lg:mt-0">
              <CustomButton
                variant="secondary"
                onClick={() => setAddModalOpen(false)}
                className="w-full"
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
      >
        <div className="grid gap-6 gap-x-4 lg:grid-cols-3">
          <div>
            <TextInput
              name="name"
              type="text"
              label="Name"
              placeholder="Aleem Shaikh"
              classes={{
                containerClassName: "custom-input",
                inputClassName: "",
              }}
              value={formik.values.name}
              showError={Boolean(formik.touched.name)}
              onChange={(e) => {
                // resetError();
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                // resetError();
              }}
              errorMessage={formik.errors.name}
              required={true}
            />
          </div>
          <div>
            <TextInput
              name="phone"
              type="text"
              label="Phone Number"
              placeholder="ex. +31 123-4567-89"
              classes={{
                containerClassName: "custom-input",
                inputClassName: "",
              }}
              value={formik.values.phone}
              showError={Boolean(formik.touched.phone)}
              onChange={(e) => {
                // resetError();
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                // resetError();
              }}
              errorMessage={formik.errors.phone}
              required={true}
            />
          </div>
          <div>
            <TextInput
              name="email"
              type="text"
              label="Email Address"
              placeholder="aleem.shaikh@aci.ae"
              classes={{
                containerClassName: "custom-input",
                inputClassName: "",
              }}
              value={formik.values.email}
              showError={Boolean(formik.touched.email)}
              onChange={(e) => {
                // resetError();
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                // resetError();
              }}
              errorMessage={formik.errors.email}
              required={true}
            />
          </div>
          <div>
            <TextInput
              name="designation"
              type="text"
              label="Designation"
              placeholder="User"
              classes={{
                containerClassName: "custom-input",
                inputClassName: "",
              }}
              value={formik.values.designation}
              showError={Boolean(formik.touched.designation)}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
              }}
              errorMessage={formik.errors.designation}
              required={false}
            />
          </div>
          <div>
            <SelectInput
              label="Customer"
              name="customer"
              searchable={true}
              options={customers}
              value={formik.values.customerid?.toString() || null}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onSelect={(e: any) => {
                if (e == "Add Customer") {
                  setAddModalOpen(true);
                } else {
                  formik.setFieldValue("customerid", e);
                }
              }}
              placeholder="Select Customer"
              containerClassName="col-span-12 sm:col-span-5"
            />
          </div>
        </div>
      </CustomModal>
    </form>
  );
};

export default AddUserComponent;
