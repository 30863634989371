import React, { useEffect, useState } from "react";

import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import {
  useDeleteCustomerUserMutation,
  useDownloadAllUsersMutation,
  useGetAllUsersListingMutation,
} from "src/services/api-service/user";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { formatDate, showToast } from "src/utils";
import { useNavigate } from "react-router-dom";
import AddUser from "../add-user";

const Datatable = () => {
  const [filtersData] = useState({
    id: 98,
  });

  const [getListMutation] = useGetAllUsersListingMutation<any>();

  const [deleteItemMutation] = useDeleteCustomerUserMutation<any>();
  const [exportCSV, { isLoading }] = useDownloadAllUsersMutation();
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const [id, setId] = useState("");

  const navigate = useNavigate();

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "user-management-datatable",
    mutation: getListMutation,
    filtersData,
  });

  const handleCellClick = (event: any) => {
    if (event?.colDef?.field !== "actions") {
      navigate(`/admin/customer-management?userId=${event?.data?.id}`);
    }
  };

  const handleOpenEditModal = (id: any) => {
    setId(id);
    setAddModalOpen(true);
  };

  useEffect(() => {
    console.log(isAddModalOpen, "isAddModalOpen");
  }, [isAddModalOpen]);

  const handleOpenDeleteModal = (id: any) => {
    setId(id);
    setDeleteModalOpen(true);
  };

  const [columnDefs] = useState<any[]>(
    columns({ handleOpenDeleteModal }, { handleOpenEditModal }),
  );

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const refetchData = () => {
    fetchData({ currentPage: pagination?.currentPage, query: "" });
  };

  const handleConfirmDelete = () => {
    const body = {
      id,
    };
    try {
      if (id) {
        deleteItemMutation(body)
          .unwrap()
          .then((data: any) => {
            showToast(
              TOASTR_TYPES.SUCCESS,
              data?.original?.message || data?.message,
            );
            refetchData();
          });
      }
    } catch (err) {
      console.log(err);
    }
    setDeleteModalOpen(false);
  };

  const handleExport = async () => {
    try {
      const response = await exportCSV().unwrap();

      const fields_to_include = [
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        {
          key: "customer",
          label: "Customers",
          special: (value: any) => value?.name || "-",
        },
        {
          key: "email",
          label: "Email",
          special: (value: any) => value || "-",
        },
        {
          key: "is_accepted",
          label: "Status",
          special: (value: any) => value?.toUpperCase() || "-",
        },
        { key: "country", label: "Country" },
        {
          key: "updated_at",
          label: "Latest Activity",
          special: (value: any) => (value ? formatDate(value) : "-"),
        },
      ];

      // Preprocess data
      const preprocessedData = response.data.map(
        (item: Record<string, any>) => {
          const filteredItem: Record<string, any> = {};
          fields_to_include.forEach(({ key, special }) => {
            const value = special ? special(item[key]) : item[key];
            filteredItem[key] = value;
          });
          return filteredItem;
        },
      );

      // Generate CSV content
      const csvContent =
        fields_to_include.map(({ label }) => `"${label}"`).join(",") +
        "\n" +
        preprocessedData
          .map((item: any) =>
            fields_to_include
              .map(({ key }) => `"${String(item[key] || "-")}"`)
              .join(","),
          )
          .join("\n");

      // Trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Failed to download users", err);
    }
  };

  return (
    <>
      <CustomDatatable
        title={"Users"}
        description={`${pagination.total} Total User${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        onCellClicked={(event: any) => handleCellClick(event)}
        button={
          <div className="flex items-center gap-3">
            <CustomButton
              disabled={isLoading}
              variant="secondary-icon-btn"
              onClick={handleExport}
            >
              <div className="flex items-center gap-2">
                <i className="shrink-0">
                  <Icon.McsIcExportPdf />
                </i>
                <span>Export CSV</span>
              </div>
            </CustomButton>

            <CustomButton
              variant="primary-icon-btn"
              onClick={() => setAddModalOpen(true)}
            >
              <div className="flex items-center gap-2">
                <i className="shrink-0">
                  <Icon.McsIcIconPlus />
                </i>
                <span>Add User</span>
              </div>
            </CustomButton>

            {/* Add Customer modal end */}
          </div>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this Customer?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />

      {/* Add Customer modal */}

      <AddUser
        id={id}
        setId={setId}
        isAddModalOpen={isAddModalOpen}
        setAddModalOpen={setAddModalOpen}
        refetchData={refetchData}
      />

      {/* Add Customer modal end */}
    </>
  );
};

export default Datatable;
