import React from "react";
import AuthLayout from "src/components/layouts/auth-layout";

import FormHeader from "src/pages/auth/elements/form-header";
import { MAILING_ADDRESS } from "src/types";

const Rejected = () => {
  return (
    <AuthLayout>
      <div className="">
        <FormHeader
          title="Your account has been rejected"
          description={
            <div className="read flex flex-col gap-y-8">
              <p>
                Thank you for your interest in Marinair Cargo Services. After
                careful review, we regret to inform you that your account
                application has been rejected.
              </p>
              <p>
                If you believe this was a mistake or would like further
                information, please feel free to reach out to our support team.
              </p>
              <p>
                Don&apos;t hesitate to{" "}
                <a
                  href={`mailto:${MAILING_ADDRESS}`}
                  className="text-16 font-bold leading-24 text-blue-1"
                >
                  contact our support team
                </a>{" "}
                for assistance or clarification regarding your application.
              </p>
            </div>
          }
        />
      </div>
    </AuthLayout>
  );
};

export default Rejected;
