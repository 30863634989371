import IcPrs from "./ic-prs";
import IcModalClose from "./ic-modal-close";
import IcUploadImage from "./ic-uploadimage";
import IcDarkBin from "./ic-dark-bin";
import IcDarkUpload from "./ic-dark-upload";
import IcCardUsersIcon from "./ic-card-users-icon";
import IcOffcanvasBackArrow from "./ic-offcanvas-back-arrow";
import IcCopy from "./ic-copy";
import IcTickArrow from "./ic-tick-icon";
import IcCloseBtn from "./ic-close-btn";

import McsIcQuotations from "./mcs-ic-quotations";
import McsIcPath from "./mcs-ic-path";
import McsIcBooking from "./mcs-ic-booking";
import McsIcQuestion from "./mcs-ic-question";
import McsIcMarginGroups from "./mcs-ic-margin-groups";
import McsIcPrices from "./mcs-ic-prices";
import McsIcPlusBlack from "./mcs-ic-plus-black";
import McsIcUserManagement from "./mcs-ic-user-management";
import McsIcAdminUserManagement from "./mcs-ic-admin-user-management";
import McsIcSettings from "./mcs-ic-settings";
import McsIcRolesAndRights from "./mcs-ic-roles-and-rights";
import McsIcMenu from "./mcs-ic-menu";
import McsIcHome from "./mcs-ic-home";
import McsIcChevronRight from "./mcs-ic-chevron-right";
import McsIcEmail from "./mcs-ic-email";
import McsIcBuilding from "./mcs-ic-building";
import McsIcCheck from "./mcs-ic-check";
import McsIcPasswrod from "./mcs-ic-password";
import McsIcEye from "./mcs-ic-eye";
import McsIcEyeOff from "./mcs-ic-eye-off";
import McsIcCreateNewPlus from "./mcs-ic-create-new-plus";
import McsIcNotificationBell from "./mcs-ic-notification-bell";
import McsIcProfileDropdownUserManagement from "./mcs-ic-profiledropdown-user-management";
import McsIcLogoutAdmin from "./mcs-ic-logout-admin";
import McsIcUploder from "./mcs-ic-uploder";
import McsIcFilePlaceholder from "./mcs-ic-fileplaceholder";
import McsIcExportPdf from "./mcs-ic-export-pdf";
import McsIcIconPlus from "./mcs-ic-icon-plus";
import McsIcSearch from "./mcs-ic-search";
import McsIcDownArrow from "./mcs-ic-down-arrow";
import McsIcEdit from "./mcs-ic-edit";
import McsIcDelete from "./mcs-ic-delete";
import McsIcChevronBack from "./mcs-ic-chevron-back";
import McsIcPlus from "./mcs-ic-plus";
import McsIcModalClose from "./mcs-ic-modal-close";
import McsIcDownload from "./mcs-ic-download";
import McsIcInvalid from "./mcs-ic-invalid";
import McsIcBack from "./mcs-ic-back";
import McsIcCirclePlus from "./mcs-ic-circle-plus";
import McsIcUserDotsThreeVertical from "./mcs-ic-dots-three-vertical";
import McsIcPrevious from "./mcs-ic-previous";
import McsIcNext from "./mcs-ic-next";
import McsIcHamburgerMenu from "./mcs-ic-hamburger-menu";
import McsIcFiltersExchange from "./mcs-ic-filters-exchange";
import McsIcSearchWhite from "./mcs-ic-search-white";
import MscIcChevronDown from "./mcs-ic-chevron-down";
import McsIcCollapseDown from "./mcs-ic-collapse-down";
import McsIcEditWhite from "./mcs-ic-edit-white";
import McsIcShip from "./mcs-ic-ship";
import McsIcTruck from "./mcs-ic-truck";
import McsIcInfo from "./mcs-ic-info";
import McsIcCheckoutline from "./mcs-ic-checkoutlined";
import McsIcCross from "./mcs-ic-cross";
import McsIcMapPin from "./mcs-ic-map-pin";
import McsIcBetween from "./mcs-ic-between";
import McsIcCaretDouble from "./mcs-ic-caret-double";
import McsIcPlusCircle from "./mcs-ic-plus-circle";
import McsIcMinusCircle from "./mcs-ic-minus-circle";
import McsIcTick from "./mcs-ic-tick";
import McsIcTickGreen from "./mcs-ic-tick-green";
import McsIcUploadCloud from "./mcs-ic-upload-cloud";
import McsIcPolygon from "./mcs-ic-polygon";
import McsIcBrandColorChevronDown from "./mcs-ic-brand-color-chevron-down";
import McsIcAir from "./mcs-ic-air";
import McsIcRail from "./mcs-ic-rail";
import McsIcApprove from "./mcs-ic-approve";
import McsIcReject from "./mcs-ic-reject";

export const Icon = {
  // Use the imported component name here
  IcPrs: IcPrs,
  McsIcTickGreen: McsIcTickGreen,
  McsIcPolygon: McsIcPolygon,
  McsIcUploadCloud: McsIcUploadCloud,
  McsIcPlusCircle: McsIcPlusCircle,
  McsIcMinusCircle: McsIcMinusCircle,
  McsIcTick: McsIcTick,
  IcUploadImage: IcUploadImage,
  IcDarkBin: IcDarkBin,
  IcDarkUpload: IcDarkUpload,
  IcModalClose: IcModalClose,
  IcCardUsersIcon: IcCardUsersIcon,
  IcOffcanvasBackArrow: IcOffcanvasBackArrow,
  IcCopy: IcCopy,
  IcTickArrow: IcTickArrow,
  IcCloseBtn: IcCloseBtn,

  McsIcQuotations: McsIcQuotations,
  McsIcQuestion: McsIcQuestion,
  McsIcBooking: McsIcBooking,
  McsIcMarginGroups: McsIcMarginGroups,
  McsIcPrices: McsIcPrices,
  McsIcUserManagement: McsIcUserManagement,
  McsIcAdminUserManagement: McsIcAdminUserManagement,
  McsIcSettings: McsIcSettings,
  McsIcRolesAndRights: McsIcRolesAndRights,
  McsIcMenu: McsIcMenu,
  McsIcHome: McsIcHome,
  McsIcCaretDouble: McsIcCaretDouble,
  McsIcChevronRight: McsIcChevronRight,
  McsIcEmail: McsIcEmail,
  McsIcBuilding: McsIcBuilding,
  McsIcPasswrod: McsIcPasswrod,
  McsIcEye: McsIcEye,
  McsIcEyeOff: McsIcEyeOff,
  McsIcPath: McsIcPath,
  McsIcCreateNewPlus: McsIcCreateNewPlus,
  McsIcNotificationBell: McsIcNotificationBell,
  McsIcProfileDropdownUserManagement: McsIcProfileDropdownUserManagement,
  McsIcLogoutAdmin: McsIcLogoutAdmin,
  McsIcUploder: McsIcUploder,
  McsIcFilePlaceholder: McsIcFilePlaceholder,
  McsIcExportPdf: McsIcExportPdf,
  McsIcIconPlus: McsIcIconPlus,
  McsIcSearch: McsIcSearch,
  McsIcDownArrow: McsIcDownArrow,
  McsIcEdit: McsIcEdit,
  McsIcDelete: McsIcDelete,
  McsIcChevronBack: McsIcChevronBack,
  McsIcPlus: McsIcPlus,
  McsIcModalClose: McsIcModalClose,
  McsIcDownload: McsIcDownload,
  McsIcInvalid: McsIcInvalid,
  McsIcBack: McsIcBack,
  McsIcCirclePlus: McsIcCirclePlus,
  McsIcUserDotsThreeVertical: McsIcUserDotsThreeVertical,
  McsIcPrevious: McsIcPrevious,
  McsIcNext: McsIcNext,
  McsIcHamburgerMenu: McsIcHamburgerMenu,
  McsIcFiltersExchange: McsIcFiltersExchange,
  McsIcSearchWhite: McsIcSearchWhite,
  MscIcChevronDown: MscIcChevronDown,
  McsIcCollapseDown: McsIcCollapseDown,
  McsIcEditWhite: McsIcEditWhite,
  McsIcShip: McsIcShip,
  McsIcAir: McsIcAir,
  McsIcRail: McsIcRail,
  McsIcTruck: McsIcTruck,
  McsIcInfo: McsIcInfo,
  McsIcCheckoutline: McsIcCheckoutline,
  McsIcCheck: McsIcCheck,
  McsIcCross: McsIcCross,
  McsIcMapPin: McsIcMapPin,
  McsIcPlusBlack: McsIcPlusBlack,
  McsIcBetween: McsIcBetween,
  McsIcBrandColorChevronDown: McsIcBrandColorChevronDown,
  McsIcApprove: McsIcApprove,
  McsIcReject: McsIcReject,
};
