import React, { useState, useEffect } from "react";
import SelectInput from "src/components/atoms/select-input";
import QuotationCard from "../quotation-card";
import { Country, IGenerateQuotation, Port } from "./types";
import { CARD_TYPES, cardData } from "../quotation-card/data";
import TextInput from "src/components/atoms/text-input";
import CustomDatePicker from "src/components/atoms/date-picker/custom-date-picker.component";
import CargoSpecs from "../cargo-specs";
import LocationPicker from "src/components/atoms/location-picker";
import { useQuotationsContext } from "src/context/quotations-context";
import moment from "moment";
import CountryPicker from "src/components/atoms/country-picker";
import PortPicker from "src/components/atoms/port-picker";
import PricingDetails from "../pricing-details";
import AddCustomerContainer from "src/pages/user-management/elements/customers/datatable/add-customer/add-customer.container";
import { useGetCountriesListMutation } from "src/services/api-service/countries";
import { useGetPostcodesListMutation } from "src/services/api-service/postcode";
import { useGetPortsListMutation } from "src/services/api-service/ports";
import { useGetUsersListMutation } from "src/services/api-service/user";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import usePickupDestination from "src/hooks/usePickupDestination";
import { checkBooleanOptions } from "src/utils";
import CountrySelecter from "src/components/atoms/country-selecter";
import PortSelecter from "src/components/atoms/port-selecter";
import useUser from "src/hooks/useUser";
import { getCurrentDate } from "src/helpers";

const GenerateQuotationComponent = ({
  quotationTypes,
  handleCardClick,
  serviceTypes,
  clicked,
}: IGenerateQuotation) => {
  const [getListMutation] = useGetUsersListMutation<any>();
  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();
  const { formikQuotation, addCargoItems } = useQuotationsContext();
  const [quotationFor, setQuotationFor] = useState<any>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id") || "";
  const type = queryParams.get("type") || "";
  const tab = queryParams.get("tab") || "";
  const user = useUser();

  const {
    originCountries,
    destinationCountries,
    originPorts,
    destinationPorts,
    resetLocationsData,
  } = usePickupDestination(formikQuotation?.values);

  useEffect(() => {
    formikQuotation?.setValues({
      ...formikQuotation.values,
      customer_id: user?.customerid,
      date: getCurrentDate(),
      cargo_item_specs: [
        {
          cm_kg: 1,
          colli_type: "Pallet",
          quantity: 0,
          length: 0,
          width: 0,
          height: 0,
          volume: 0,
          weight: 0,
          stackable: 1,
        },
      ],
    });
  }, [tab]);

  useEffect(() => {
    id && getDetails();
  }, [id]);

  const getDetails = () => {
    const body = {
      id: id,
    };
    try {
      // startLoading();
      id &&
        getDetailsMutation(body)
          .unwrap()
          .then((response: any) => {
            const syncData: any = response?.data;
            let is_cm = false;

            if (syncData?.cargo_item_specs?.length > 0) {
              is_cm = syncData?.cargo_item_specs[0]?.cm_kg;
            }

            formikQuotation.setValues((values: any) => ({
              ...values,
              quotation_type: "external",
              customer_id: JSON.stringify(syncData?.customer_id),
              reference_no: syncData?.quotations[0].reference_no,
              date: syncData?.date || new Date(),
              source_country: syncData?.source_country,
              source_location: syncData?.source_location,
              source_port: syncData?.source_port,
              destination_country: syncData?.destination_country,
              destination_location: syncData?.destination_location,
              destination_port: syncData?.destination_port,
              service_type: syncData?.service_type,
              total_cargo_dimensions:
                !!syncData?.cargo_total_dimensions?.length,
              is_cm,
              cargo_item_specs: syncData?.cargo_total_dimensions?.length
                ? syncData?.cargo_total_dimensions
                : syncData?.cargo_item_specs,
            }));
            // addCargoItems(syncData?.cargo_item_specs);
            if (syncData?.cargo_item_specs?.length > 0) {
              is_cm = syncData?.cargo_item_specs[0]?.cm_kg;
              const items: any[] = syncData?.cargo_item_specs?.map(
                (item: any) => {
                  return {
                    ...item,
                    stackable: checkBooleanOptions(item.stackable),
                  };
                },
              );
              formikQuotation?.setFieldValue("cargo_item_specs", items);
            }

            setQuotationFor("1");
            // syncData?.source_country &&
            //   fetchPorts(syncData?.source_country, true);
            // syncData?.destination_country &&
            //   fetchPorts(syncData?.destination_country, false);
          });
    } finally {
      // stopLoading();
    }
  };

  // const [countries, setCountries] = useState<Country[]>([]);
  // const [sourcePorts, setSourcePorts] = useState<Port[]>([]);
  // const [destinationPorts, setDestinationPorts] = useState<Port[]>([]);

  const [getCountriesList, { data: countriesData }] =
    useGetCountriesListMutation();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        await getCountriesList({});
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [getCountriesList]);

  // Fetch postcodes for source location
  const [getPostcodesList, { data: PostcodesData }] =
    useGetPostcodesListMutation();
  useEffect(() => {
    const fetchPostcodes = async () => {
      try {
        await getPostcodesList({});
      } catch (error) {
        console.error("Error fetching source postcodes:", error);
      }
    };
    fetchPostcodes();
  }, [getPostcodesList]);

  useEffect(() => {
    !id &&
      quotationFor == "2" &&
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
  }, [quotationFor]);

  useEffect(() => {
    !id &&
      formikQuotation.values.service_type &&
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
  }, [formikQuotation.values.service_type]);

  useEffect(() => {
    !id &&
      formikQuotation?.values?.customer_id &&
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
  }, [formikQuotation?.values?.customer_id]);

  // useEffect(() => {
  //   if (portsData && Array.isArray(portsData)) {
  //     // Assuming portsData is an array of objects containing name and locode properties
  //     const mappedPorts: Port[] = portsData.map((port) => ({
  //       name: port.name,
  //       locode: port.locode,
  //       aliases: port.aliases ? port.aliases : undefined,
  //     }));
  //     setPorts(mappedPorts);
  //   }
  // }, [portsData]);

  return (
    <div className="p-24">
      <div className="flex flex-col gap-1">
        <h1 className="text-24 font-bold leading-32 text-gray-800">
          Create your Quote
        </h1>
        <div className="text-14 font-normal leading-20 text-gray-500-base">
          Complete the required fields and get your personalized quote
        </div>
      </div>
      <div className="mb-[176px] mt-10 flex flex-col rounded-[10px] border border-border">
        <span className="hidden"> Service Type </span>
        <div className="mb-24 px-24">
          <h2 className="my-12 text-18 font-semibold leading-28 text-primary-800">
            Service Type
          </h2>
          <div className="flex gap-x-6">
            {serviceTypes.map((card, idx) => (
              <QuotationCard
                key={idx}
                {...card}
                handleCardClick={() =>
                  handleCardClick(card?.value, CARD_TYPES.SERVICE_TYPE)
                }
                cardType={CARD_TYPES.SERVICE_TYPE}
                isActive={formikQuotation?.values.service_type == card?.value}
              />
            ))}
          </div>
        </div>
        {formikQuotation?.values?.service_type && (
          <>
            <span className="hidden"> Routing Details </span>
            <div className="mb-24 px-24 pb-16">
              <h3 className="pb-12 text-18 font-semibold leading-28 text-primary-800">
                Routing Details
              </h3>
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 xl:col-span-5">
                  <TextInput
                    name="reference_no"
                    type="text"
                    label="Reference No."
                    placeholder="Type here.."
                    value={formikQuotation?.values?.reference_no}
                    onChange={formikQuotation?.handleChange}
                    // required={true}
                  />
                </div>
                <div className="col-span-12 xl:col-span-5">
                  <CustomDatePicker
                    name="date"
                    label="Cargo Ready date"
                    containerClassName="cargo-ready-date-picker"
                    value={formikQuotation?.values?.date}
                    onChange={(date: any, dateString: any) => {
                      formikQuotation?.setFieldValue("date", date);
                    }}
                    errorMessage={formikQuotation?.errors?.date}
                    showError={formikQuotation?.touched?.date}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
              {/* <h4 className="pb-12 text-16 font-semibold leading-28 text-primary-800">
                    Pickup Location
                  </h4> */}
              <div className="col-span-12 xl:col-span-4">
                <CountrySelecter
                  placeholder="Enter a Country"
                  name="source_country"
                  label="Pickup Country"
                  value={formikQuotation?.values?.source_country}
                  onChange={(value: string) => {
                    formikQuotation.handleChange;
                    formikQuotation?.setFieldValue("source_country", value);
                  }}
                  onSelect={(value: string) => {
                    resetLocationsData("originPorts");
                    resetLocationsData("destinationCountries");
                    resetLocationsData("destinationPorts");

                    formikQuotation?.setValues({
                      ...formikQuotation?.values,
                      source_port: "",
                      destination_country: "",
                      destination_port: "",
                    });
                    formikQuotation?.setFieldValue("source_country", value);
                  }}
                  countries={originCountries} // Pass the fetched countries data here
                  required={true}
                />
              </div>
              <div className="col-span-12 xl:col-span-4">
                <PortSelecter
                  placeholder="Select a Port"
                  name="source_port"
                  label="Origin Port"
                  value={formikQuotation?.values?.source_port}
                  onChange={(value: string) => {
                    formikQuotation.handleChange;
                    formikQuotation?.setFieldValue("source_port", value);
                  }}
                  onSelect={(value: string) => {
                    resetLocationsData("destinationCountries");
                    resetLocationsData("destinationPorts");
                    formikQuotation?.setValues({
                      ...formikQuotation?.values,
                      destination_country: "",
                      destination_port: "",
                    });
                    formikQuotation?.setFieldValue("source_port", value);
                  }}
                  ports={originPorts} // Pass the fetched ports data here
                  required={true}
                />
              </div>
              <div className="col-span-12 xl:col-span-4">
                <LocationPicker
                  placeholder="Enter Pickup Location"
                  name="source_location"
                  label="Pickup Location"
                  value={formikQuotation?.values?.source_location}
                  onChange={(value: string) => {
                    formikQuotation.setFieldValue("source_location", value);
                  }}
                  onSelect={(value: string) => {
                    formikQuotation.setFieldValue("source_location", value);
                  }}
                  postcodes={PostcodesData || []} // Pass postcodes data here
                  countryCode={formikQuotation?.values?.source_port?.substring(
                    0,
                    2,
                  )}
                  disabled={!Boolean(formikQuotation.values.source_port)}
                />
              </div>
            </div>
            <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
              {/* <h4 className="pb-12 text-16 font-semibold leading-28 text-primary-800">
                    Destination Location
                  </h4> */}
              <div className="col-span-12 xl:col-span-4">
                <CountrySelecter
                  placeholder="Enter a Country"
                  name="destination_country"
                  label="Destination Country"
                  value={formikQuotation?.values?.destination_country}
                  onChange={(value: string) => {
                    formikQuotation.handleChange;
                    formikQuotation?.setFieldValue(
                      "destination_country",
                      value,
                    );
                  }}
                  onSelect={(value: string) => {
                    resetLocationsData("destinationPorts");
                    formikQuotation?.setValues({
                      ...formikQuotation?.values,
                      destination_port: "",
                    });
                    formikQuotation?.setFieldValue(
                      "destination_country",
                      value,
                    );
                  }}
                  countries={destinationCountries} // Pass the fetched countries data here
                  required={true}
                />
              </div>
              <div className="col-span-12 xl:col-span-4">
                <PortSelecter
                  placeholder="Select a Port"
                  name="destination_port"
                  label="Destination Port"
                  value={formikQuotation?.values?.destination_port}
                  onChange={(value: string) => {
                    formikQuotation.handleChange;
                    formikQuotation?.setFieldValue("destination_port", value);
                  }}
                  onSelect={(value: string) => {
                    formikQuotation?.setFieldValue("destination_port", value);
                  }}
                  ports={destinationPorts} // Pass the fetched ports data here
                  required={true}
                />
              </div>
              <div className="col-span-12 xl:col-span-4">
                <LocationPicker
                  placeholder="Enter Destination Location"
                  name="destination_location"
                  label="Destination Location"
                  value={formikQuotation?.values?.destination_location}
                  onChange={(value: string) => {
                    formikQuotation.setFieldValue(
                      "destination_location",
                      value,
                    );
                  }}
                  onSelect={(value: string) => {
                    formikQuotation.setFieldValue(
                      "destination_location",
                      value,
                    );
                  }}
                  postcodes={PostcodesData || []}
                  countryCode={formikQuotation?.values?.destination_port?.substring(
                    0,
                    2,
                  )}
                  disabled={!Boolean(formikQuotation.values.destination_port)}
                />
              </div>
            </div>
          </>
        )}
        {formikQuotation?.values?.source_country &&
          formikQuotation?.values?.source_port &&
          formikQuotation?.values?.destination_country &&
          formikQuotation?.values?.destination_port &&
          formikQuotation?.values?.date && (
            <>
              <span className="hidden"> Cargo Specs </span>
              <div className="mb-24 px-24 pb-16">
                <CargoSpecs />
              </div>
              {formikQuotation?.values?.quotation_type ===
                quotationTypes[1].value && <PricingDetails />}
            </>
          )}
      </div>
    </div>
  );
};

export default GenerateQuotationComponent;
